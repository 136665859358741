import React from 'react';
import SEO from './SEO';

const Layout = ({ children, seo }) => (
  <div>
    <SEO {...seo} />
    <div style={{ paddingTop: '100px' }}>
      {children}
    </div>
  </div>
);

export default Layout;
